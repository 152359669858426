import { Box, Grid, Stack, Typography, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState, useContext } from 'react';
import { FormContolAutocomplete } from './components/FormContolAutocomplete';
import { ChipTasks, DialogActionsStyle, SendMessageStyled } from './styled';
import { ReactComponent as Delete } from 'src/icons/delete.svg';
import { ButtonCancel, ButtonSend } from '../styled';
import { useForm, Controller, useWatch } from 'react-hook-form';
import { chatsActions, ChatsContext } from 'src/context/chats';
import { sendAssignmentAddOnTestRequest } from 'src/api/assignments/assignments';
import { defaultValues, fastingTypes, MAX_MESSAGE_LENGTH } from './consts';

import { getOrdersAction, getTestsAction, getProviders, changeDataForSend, getOrderInformation } from './helpers';
import { portalActions, PortalContext } from 'src/context/portal';
import { SearchType } from 'src/api/chats/models';
import { ProfileContext } from 'src/context/profile';
import { ORDERS_CHANGING_PERMISSION, scrollBar } from 'src/const';
import { theme } from 'src/theme';
import { DiagnosisItem, SelectBillingType } from './components';
import { DiagnosesInput } from './components/DiagnosesInput';
import { Modal } from './components/Modal';
import { DiagnosisForm, TestForm } from 'src/api/assignments/models';

type TestRequestModalProps = {
  isOpenTestRequestModal: boolean;
  setIsOpenTestRequestModal: React.Dispatch<React.SetStateAction<boolean>>;
};

export type TDataForm = {
  message: string;
  originalOrderNumber: ISelectOption<string> | string;
  tests: TestForm[];
  primaryDiagnosis: DiagnosisForm;
  secondaryDiagnoses: DiagnosisForm[];
  diagnoses: string[];
  orderingProvider: ISelectOption<string> | string;
  billType: string;
  fasting: string;
  clientCompanyId: string;
};

export const TestRequest: React.FC<TestRequestModalProps> = ({ isOpenTestRequestModal, setIsOpenTestRequestModal }) => {
  const { activeChat, chats, activeChatLabCode } = useContext(ChatsContext);
  const { orderId } = useContext(PortalContext);
  const { permissions } = useContext(ProfileContext);
  const { getValues, control, watch, setValue, reset } = useForm<TDataForm>({
    defaultValues,
    mode: 'onChange',
  });

  const [originalOrderNumber, orderingProvider, testsForm, primaryDiagnosisForm, secondaryDiagnosesForm] = useWatch({
    control,
    name: ['originalOrderNumber', 'orderingProvider', 'tests', 'primaryDiagnosis', 'secondaryDiagnoses'],
  });
  const disabledApply =
    !originalOrderNumber || !orderingProvider || testsForm?.length === 0 || !primaryDiagnosisForm.esbId;
  const [tests, setTests] = useState<ISelectOption<string>[]>([]);

  const [providers, setProviders] = useState<ISelectOption<string>[]>([]);
  const [orderingProviderInOrder, setOrderingProviderInOrder] = useState<string>('');
  const [patient, setPatient] = useState<{ name: string; patientId: string }>({ name: '', patientId: '' });

  const [ordersOptions, setOrdersOption] = useState<ISelectOption<string>[]>([]);
  const [labCodeInOrder, setLabCodeInOrder] = useState<string>('');

  const [orderIdNumber, setOrderIdNumber] = useState('');

  const orderChangingAvailable = permissions.includes(ORDERS_CHANGING_PERMISSION);

  const reserForm = () => {
    setLabCodeInOrder('');
    reset(defaultValues);
    setPatient({ name: '', patientId: '' });
    setProviders([]);
    portalActions?.resetParams();
    setIsOpenTestRequestModal(false);
  };

  const onSubmit = () => {
    const data = getValues();
    void sendAssignmentAddOnTestRequest(changeDataForSend(data, activeChat)).then(() => {
      portalActions?.resetParams();
      return reserForm();
    });
  };

  const handleClose = () => {
    reserForm();
  };

  const watchOrder = watch('originalOrderNumber') as unknown as ISelectOption<string>;
  const watchOrderingProvider = watch('orderingProvider') as unknown as ISelectOption<string>;
  const testsIncluded = watchOrder?.label && watchOrderingProvider?.value;

  useEffect(() => {
    if (watchOrder) {
      const id: string = watchOrder.label;
      getOrderInformation({
        setLabCodeInOrder,
        setPatient,
        setValue,
        setOrderingProviderInOrder,
        orderId: id,
        setOrderIdNumber,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchOrder]);

  useEffect(() => {
    if (orderId) setValue('originalOrderNumber', { label: orderId, value: orderId });
  }, [orderId, setValue]);

  useEffect(() => {
    if (labCodeInOrder && chats.length > 0 && orderChangingAvailable) {
      const chatWithThisLab = chats.find(chat => chat.laboratoryCode === labCodeInOrder);
      if (chatWithThisLab) {
        chatsActions?.setActiveChat(chatWithThisLab.id);
        chatsActions?.setCreatedActiveChat(chatWithThisLab.lastMessage?.created);
        chatsActions?.setActiveChatLabCode(chatWithThisLab.laboratoryCode);
        setIsOpenTestRequestModal(true);
      } else {
        void chatsActions.addChatToTop(labCodeInOrder, SearchType.ByLabcode);
        chatsActions?.setActiveChatLabCode('');
      }
    }
  }, [labCodeInOrder, chats, orderChangingAvailable, setIsOpenTestRequestModal]);

  useEffect(() => {
    if (testsIncluded) {
      getTestsAction({
        setTests,
        providerId: watchOrderingProvider.value,
        orderId: watchOrder.label,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [testsIncluded]);

  useEffect(() => {
    if (isOpenTestRequestModal) {
      void getOrdersAction({
        setOrdersOption,
        search: {
          search: orderId || '',
        },
        labCode: activeChatLabCode,
      })
        ?.then(res => {
          return res && orderId ? setValue('originalOrderNumber', res) : null;
        })
        .catch(error => console.log(error));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenTestRequestModal]);

  useEffect(() => {
    if (providers.length > 0 && orderingProviderInOrder && setValue) {
      const currentProvider = providers.find(provider => provider.value === orderingProviderInOrder);
      if (currentProvider) {
        setValue('orderingProvider', {
          value: currentProvider.value,
          label: currentProvider.label,
        });
      }
    }
    if (!orderingProviderInOrder) {
      setValue('orderingProvider', '');
    }
  }, [providers, orderingProviderInOrder, setValue, watchOrder]);

  useEffect(() => {
    if (labCodeInOrder) {
      getProviders({ setProviders, search: '', labCodeInOrder });
    }
  }, [labCodeInOrder]);

  useEffect(() => {
    setValue('tests', []);
  }, [watchOrderingProvider, watchOrder, setValue]);

  const deleteTest = (value: string) => {
    const newArrayTests: TestForm[] = testsForm?.filter((item: TestForm) => item.id !== value);

    setValue('tests', newArrayTests);
  };

  const addTest = (testsValue: ISelectOption<string> | ISelectOption<string>[] | null) => {
    if (Array.isArray(testsValue)) {
      const normalizedTests: TestForm[] = testsValue.map(item => {
        if ('value' in item && 'label' in item) {
          return { id: item.value, name: item.label };
        }

        return item as unknown as TestForm;
      });

      const uniqueTestsMap = new Map<string, TestForm>(normalizedTests.map(item => [item.id, item]));

      const uniqueTests = [...uniqueTestsMap.values()];
      setValue('tests', uniqueTests);
    }
  };

  const updateDiagnoses = (selectedDiagnoses: DiagnosisForm[]) => {
    // eslint-disable-next-line unicorn/prefer-array-find
    const primaryDiagnosis = selectedDiagnoses.filter((f: DiagnosisForm) => f?.esbId.length > 0)[0];
    const secondaryDiagnoses = selectedDiagnoses.filter((f: DiagnosisForm) => f?.esbId.length > 0).slice(1);
    setValue('primaryDiagnosis', primaryDiagnosis || {});
    setValue('secondaryDiagnoses', secondaryDiagnoses);
  };

  const makePrimary = (diagosis: DiagnosisForm) => {
    setValue('secondaryDiagnoses', [
      ...secondaryDiagnosesForm.filter(di => di.esbId !== diagosis.esbId),
      primaryDiagnosisForm,
    ]);
    setValue('primaryDiagnosis', diagosis);
  };

  const deleteDiagnosis = (value: DiagnosisForm) => {
    const newArrayDiagnoses = [primaryDiagnosisForm, ...secondaryDiagnosesForm].filter(
      (item: DiagnosisForm) => item.esbId !== value.esbId,
    );
    updateDiagnoses(newArrayDiagnoses);
  };

  const addDiagnoses = (diagnosesValue: DiagnosisForm[]) => {
    const uniqueDiagnoses = [...new Map(diagnosesValue.map(item => [item.esbId, item])).values()];
    updateDiagnoses(uniqueDiagnoses);
  };

  return (
    <Modal
      open={isOpenTestRequestModal}
      onCancel={handleClose}
      wrapperProps={{
        PaperProps: {
          sx: {
            maxWidth: '800px',
            width: '100%',
            maxHeight: '802px',
            height: 'calc(100vh * 0.7)',
            background: 'white',
            overflow: 'initial',
          },
        },
      }}
      bodySx={{ padding: 0 }}
      title="Add On Tests Request"
    >
      <Box sx={{ maxWidth: '100%' }}>
        <Box sx={{ padding: '12px 24px', height: 'calc(100% - 91px)', overflow: 'auto', ...scrollBar }}>
          <Stack width="100%" direction="row" gap="48px" padding="12px 0 24px" alignItems="center">
            <FormContolAutocomplete
              name="originalOrderNumber"
              control={control}
              required={true}
              withoutArrow
              variant="outlined"
              setState={{ setOrdersOption }}
              onSearch={(searchValue: string) =>
                getOrdersAction({
                  setOrdersOption,
                  search: searchValue,
                  labCode: activeChatLabCode,
                })
              }
              sxControl={{ maxWidth: '352px' }}
              label="Original Order Number"
              placeholder="Search"
              options={ordersOptions}
            />
            {patient.name && (
              <Stack direction="row" gap="12px" alignItems="center" sx={{ transform: 'translate(0, 10px)' }}>
                <Typography color="grey.700" variant="label14Bold" lineHeight="20px">
                  Patient
                </Typography>
                <Typography color="grey.700" variant="label14Regular" lineHeight="20px">
                  {patient.name}
                </Typography>
              </Stack>
            )}
          </Stack>
          <Stack direction="row" gap="48px" width="100%" padding="12px 0 24px">
            <FormContolAutocomplete
              label="Ordering Provider"
              name="orderingProvider"
              control={control}
              required
              disableClearable={false}
              placeholder="Select"
              options={providers}
              setState={{ setProviders }}
            />
            <SelectBillingType control={control} />
          </Stack>
          <Box>
            <FormContolAutocomplete
              label="Tests"
              multiple
              name="tests"
              control={control}
              required
              disabled={!testsIncluded}
              setState={{ setTests }}
              onSearch={() =>
                getTestsAction({
                  setTests,
                  providerId: watchOrderingProvider.value,
                  orderId: watchOrder.label,
                })
              }
              placeholder="Select"
              options={tests}
              onChange={(_, testsV) => addTest(testsV)}
            />
          </Box>
          <Box padding={testsForm.length > 0 ? '12px 0' : '0 0 24px 0'}>
            <Grid container columnGap="28px" rowGap="20px" sx={{ padding: testsForm.length > 0 ? '10px 0' : 0 }}>
              {testsForm?.map((test: TestForm) => (
                <Grid item key={test.id}>
                  <ChipTasks
                    label={test.name}
                    color="primary"
                    clickable
                    deleteIcon={<Delete style={{ fill: theme.palette.secondary.main }} />}
                    onDelete={() => deleteTest(test.id)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          <Controller
            name="fasting"
            control={control}
            render={({ field: { onChange, value: fieldValue } }) => (
              <Stack gap="6px" mb="24px">
                <Typography color="grey.700" variant="label14Bold" lineHeight="18px">
                  Fasting
                </Typography>
                <RadioGroup
                  sx={{ flexDirection: 'row', gap: '24px' }}
                  value={fieldValue}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  name="radio-buttons-group"
                >
                  {fastingTypes.map(({ name, value }) => (
                    <FormControlLabel
                      label={
                        <Typography color="#4B5563" variant="label14Medium">
                          {name}
                        </Typography>
                      }
                      value={value}
                      labelPlacement="end"
                      sx={{ gap: '12px' }}
                      control={<Radio color="secondary" size="small" />}
                    />
                  ))}
                </RadioGroup>
              </Stack>
            )}
          />

          <DiagnosesInput
            control={control}
            disabled={[primaryDiagnosisForm, ...secondaryDiagnosesForm].length > 99 || testsForm?.length < 1}
            selectedDiagnoses={[primaryDiagnosisForm, ...secondaryDiagnosesForm].filter(di => di.esbId?.length > 0)}
            addDiagnoses={addDiagnoses}
            isOpenTestRequestModal={isOpenTestRequestModal}
            patientId={patient.patientId}
            orderId={orderIdNumber}
          />
          {primaryDiagnosisForm.esbId && (
            <Box padding="16px 0">
              <Stack gap="12px">
                <Typography variant="label14Bold" color="grey.700" lineHeight="18px">
                  Primary Diagnosis
                </Typography>
                <DiagnosisItem
                  marginBottom={secondaryDiagnosesForm?.length > 0 ? '24px' : '0'}
                  diagnosis={primaryDiagnosisForm}
                  deleteDiagnosis={deleteDiagnosis}
                />
              </Stack>
              {secondaryDiagnosesForm?.length > 0 && (
                <Stack gap="12px">
                  <Typography variant="label14Bold" color="grey.700" lineHeight="18px">
                    Secondary Diagnoses
                  </Typography>
                  {secondaryDiagnosesForm?.map((diagnosis: DiagnosisForm) => (
                    <DiagnosisItem
                      key={diagnosis.esbId}
                      marginBottom="0"
                      deleteDiagnosis={deleteDiagnosis}
                      diagnosis={diagnosis}
                      makePrimary={makePrimary}
                    />
                  ))}
                </Stack>
              )}
            </Box>
          )}
          <Box padding="12px 0">
            <Stack direction="row" gap="12px" alignItems="end" marginBottom="8px">
              <Typography variant="label14Medium" color="grey.700">
                Message
              </Typography>
              <Typography variant="label12Medium" color="grey.300" fontStyle="italic">
                Optional
              </Typography>
            </Stack>
            <Controller
              name="message"
              control={control}
              rules={{ required: false, minLength: 0 }}
              render={({ field: { onChange, value } }) => (
                <>
                  <SendMessageStyled
                    multiline
                    fullWidth
                    rows={5}
                    value={value}
                    onChange={value.length < MAX_MESSAGE_LENGTH ? onChange : () => {}}
                  />
                  <Stack
                    sx={{ color: '#6B7280', fontSize: '14px', lineHeight: '20px', marginTop: '2px' }}
                    direction="row"
                    justifyContent="space-between"
                  >
                    <Typography color="grey.700" lineHeight="16px" variant="label12Medium">
                      This text will be visible only to the laboratory
                    </Typography>
                    <Typography color="grey.700" lineHeight="16px" variant="label12Medium">
                      {value.length}/{MAX_MESSAGE_LENGTH}
                    </Typography>
                  </Stack>
                </>
              )}
            />
          </Box>
        </Box>
        <DialogActionsStyle>
          <ButtonCancel variant="outlined" color="secondary" onClick={handleClose}>
            Cancel
          </ButtonCancel>
          <ButtonSend
            variant="contained"
            color="secondary"
            onClick={onSubmit}
            autoFocus
            type="submit"
            disabled={!!disabledApply}
          >
            Submit
          </ButtonSend>
        </DialogActionsStyle>
      </Box>
    </Modal>
  );
};
